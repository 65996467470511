/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { useContext } from '@nuxtjs/composition-api';
import axios from 'axios';
import { isClient } from '~/helpers/check-environment';
import { InternalEndpoints } from '~/enums/internalEndpoints';
import { sharedRef } from '@vue-storefront/core';
import CryptoJS from 'crypto-js';

const useDiscounts = () => {
  const {
    app: {
      $config: { ssrMiddlewareUrl, middlewareUrl },
    },
  } = useContext();

  const discounts = sharedRef([], 'loadDiscounts-discounts');

  const loadDiscounts = async () => {
    try {
      const { data } = await axios.get(`${isClient ? middlewareUrl : ssrMiddlewareUrl}${InternalEndpoints.LoadDiscounts}`);
      const bytes = CryptoJS.AES.decrypt(data.data, 'J3JyYZrnqNwc5XXG2u&_*t=qgFyQD2R@Hu9d5V8caSpCPrL^h85rTG-7mx2cah*P');
      const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

      discounts.value = decryptedData || [];
      return true;
    } catch (e) {
      console.error(e);
    }
    return false;
  };

  return {
    loadDiscounts,
    discounts,
  };
};

export default useDiscounts;
