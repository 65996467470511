/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
/* eslint-disable max-statements */
/* eslint-disable complexity */
/* eslint-disable unicorn/prefer-logical-operator-over-ternary */
// eslint-disable-next-line import/no-extraneous-dependencies
import { sharedRef } from '@vue-storefront/core';
import { useCart, useTranslation, useUiNotification, useUser } from '~/composables';
import dataManipulation from '~/helpers/dataManipulation';
import { onMounted, useRoute, useRouter, computed, useContext, ref } from '@nuxtjs/composition-api';
import { setItem } from '~/helpers/asyncLocalStorage';
import { checkoutErrorsMap } from '~/helpers/maps';
import {
  useShipping,
  useAddresses,
  useShippingProvider,
  useBilling,
  useMakeOrder,
  ShippingMethod,
  usePaymentProvider,
} from '@gemini-vsf/composables';
import { addressFromApiToForm, addressFormToApi } from '~/helpers/checkout/address';
import { CheckoutStateReferences as State, CheckoutStep } from '~/types/enums';
import { useI18n } from '~/helpers/hooks/usei18n';
import { CheckoutState } from '~/types/types';
import { AdditionalProductData } from '~/components/Checkout/ThankYou/OrderSummary.vue';
import * as mockedData from '~/.dev/mocks/order/mockedOrder';
import { formatMoney } from '~/helpers/checkout/money';
import { generateOrderSummaryPdf as generateOrderSummary } from './thank-you/generateOrderSummaryPdf';

const { findErrorInResponse, getErrorDataFromPlaceResponse, getErrorDataForNotification } = dataManipulation();

const STEPS = {
  [CheckoutStep.Shipping]: 'Shipping',
  [CheckoutStep.Payment]: 'Payment',
};

/**
 * Returns the index of the given step in the STEPS object.
 * @param step - The step to get the index of.
 * @returns The index of the given step in the STEPS object.
 */
const getCurrentStepIndex = (step: string) => Object.keys(STEPS).indexOf(step);

/**
 * Returns an object containing the state of the checkout process.
 * @param cartId - The ID of the cart to use for the checkout process.
 * @param hasToMount
 * @returns An object containing the state of the checkout process.
 */
const useCheckout = (cartId: string, hasToMount = true): CheckoutState => {
  const { cart, load: loadCart, removeCart, checkBulkyProducts, cartNotes } = useCart();
  const { user, load: loadUser, customerIsSubscribed } = useUser();
  const { save: saveShippingAddress, error: shippingErrors, shipping: shippingData } = useShipping();
  const { save: setBillingAddressOnCartComposable, error: billingErrors } = useBilling();
  const { save: createCustomerAddress } = useAddresses();
  const { send: sendNotification } = useUiNotification();
  const { save: setShippingMethodOnCartComposable, error: shippingMethodErrors } = useShippingProvider();
  const { load: loadPaymentMethods } = usePaymentProvider();
  // const { order } = mockedData;
  const { make: placeOrderComposable, error: orderErrors, order } = useMakeOrder();
  const router = useRouter();
  const route = useRoute();
  const { $gt } = useTranslation('translations');
  const { locale } = useI18n();
  const { redirect, $dateFns } = useContext();

  /**
   * Shows a notification with the given error message for the given step.
   * @param step - The step where the error occurred.
   * @param key - The key of the error message in the checkoutErrorsMap.
   * @param errorMessage
   */
  const notifyCheckoutError = (step: string, key: string, errorMessage?: string) => {
    try {
      const { title, message } = checkoutErrorsMap[step][key];
      sendNotification({
        id: Symbol('checkout_error'),
        message: !errorMessage ? $gt(message) : $gt(errorMessage),
        type: 'danger',
        icon: 'check',
        persist: false,
        title,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const checkoutLoading = sharedRef(true, `${State.Loading}${cartId}`);
  const stepLoading = sharedRef(false, `${State.StepLoading}${cartId}`);
  const checkoutUser = sharedRef(null, `${State.User}${cartId}`);
  const checkoutShipping = sharedRef(null, `${State.Shipping}${cartId}`);
  const checkoutShippingAddressId = sharedRef(null, `${State.ShippingAddressId}${cartId}`);
  const checkoutShippingMethod = sharedRef(null, `${State.ShippingMethod}${cartId}`);
  const checkoutBilling = sharedRef(null, `${State.Billing}${cartId}`);
  const checkoutBillingAddressId = sharedRef(null, `${State.BillingAddressId}${cartId}`);
  const checkoutPayment = sharedRef(null, `${State.Payment}${cartId}`);
  const checkoutThankYouAdditionalData = sharedRef(null, `${State.ThankYouAdditionalData}${cartId}`);
  const checkoutShippingIsBeingSet = sharedRef(true, `${State.ShippingBeingSet}${cartId}`);
  const checkoutPlacingOrder = sharedRef(false, `${State.PlacingOrder}${cartId}`);
  const checkoutOrderNotes = sharedRef(false, `${State.OrderNotes}${cartId}`);
  const checkoutOrderTotals = sharedRef(false, `${State.OrderTotals}${cartId}`);
  const checkoutOrderShippingAddress = sharedRef(false, `${State.OrderShippingAddress}${cartId}`);
  const preservedCartNotes = sharedRef(null, `${State.PreservedCartNotes}${cartId}`);
  const currentStep = computed(() => route?.value?.path?.split('/')?.pop());
  const currentStepIndex = computed(() => Object.keys(STEPS).indexOf(currentStep.value));
  const cartCanBeCheckedOut = computed(
    () => cart?.value?.items?.length > 0 && cart.value.billing_address && cart.value.shipping_addresses?.length > 0
  );
  const checkoutAvailableShippingMethods = computed(
    () => shippingData?.value?.available_shipping_methods?.length > 0 && shippingData?.value?.available_shipping_methods
  );
  const isNormal = sharedRef(true, `cart-normal-${cartId}`);
  const isPickup = sharedRef(false, `cart-pickup-${cartId}`);
  const generateOrderSummaryPdf = () =>
    generateOrderSummary({
      order: order.value,
      shippingAddress: checkoutShipping.value,
      billingAddress: checkoutBilling.value,
      notifyCheckoutError,
      checkoutUser,
      additionalOrderItemsData: checkoutThankYouAdditionalData.value as AdditionalProductData,
      $dateFns,
      footerText: $gt('ORDER_SUMMARY_FOOTER_TEXT'),
      orderNotes: checkoutOrderNotes.value,
      orderShippingAddress: checkoutOrderShippingAddress.value,
      totalNoTax: checkoutOrderTotals.value.totalItems,
    });

  /**
   * Sets the given user object as the checkout user and saves it to local storage.
   * @param userParam - The user object to set as the checkout user.
   */
  const setCheckoutUser = async (userParam: any) => {
    checkoutUser.value = userParam;
    await setItem(`user`, userParam);
  };

  /**
   * Sets the given shipping method on the cart and navigates to the billing step.
   * @param shippingMethod - The shipping method to set on the cart.
   * @param goToBilling - Whether to navigate to the billing step after setting the shipping method.
   */
  const setShippingMethodOnCart = async (shippingMethod: ShippingMethod, goToBilling = false) => {
    stepLoading.value = true;
    try {
      await setShippingMethodOnCartComposable({
        shippingMethod: { carrier_code: shippingMethod.carrier_code, method_code: shippingMethod.method_code },
        customQuery: {
          setShippingMethodsOnCart: 'setShippingMethodsOnCartCustom',
        },
      });
      const setError = findErrorInResponse(shippingMethodErrors.value);
      if (setError) {
        notifyCheckoutError(CheckoutStep.Shipping, setError);
        console.error('setShippingMethodOnCart ~ setError:', setError);
        stepLoading.value = false;
        return;
      }

      await setItem(`method`, JSON.stringify(shippingMethod));
      checkoutShippingMethod.value = shippingMethod;
      stepLoading.value = false;
      checkoutShippingIsBeingSet.value = false;
    } catch (error) {
      console.error('setShippingMethodOnCart ~ error:', error);
    }
    stepLoading.value = false;
    checkoutShippingIsBeingSet.value = false;
  };

  /**
   * Sets the given address as the checkout shipping address.
   * @param address - The address to set as the checkout shipping address.
   * @param tryDefault - Whether to try to use the default shipping address if the given address is null or undefined.
   */
  const setCheckoutShipping = async (address: any, tryDefault = false) => {
    if (tryDefault) {
      // this happens only onMounted
      const defaultAddress = address.find((a: any) => a.default_shipping);
      if (defaultAddress) {
        checkoutShipping.value = defaultAddress;
        checkoutShippingAddressId.value = defaultAddress.id;
        await setItem(CheckoutStep.Shipping, defaultAddress);
      }
      return;
    }
    checkoutShipping.value = address;
    checkoutShippingAddressId.value = address.id;
    await setItem(CheckoutStep.Shipping, address);
  };

  /**
   * Sets the given address as the checkout billing address.
   * @param address - The address to set as the checkout billing address.
   * @param tryDefault - Whether to try to use the default billing address if the given address is null or undefined.
   */
  const setCheckoutBilling = async (address: any, tryDefault = false) => {
    if (tryDefault) {
      const defaultAddress = address.find((a: any) => a.default_billing);
      if (defaultAddress) {
        checkoutBilling.value = defaultAddress;
        checkoutBillingAddressId.value = defaultAddress.id;
        await setItem(CheckoutStep.Billing, defaultAddress);
      }
      return;
    }
    checkoutBilling.value = address;
    checkoutBillingAddressId.value = address.id;
    await setItem(CheckoutStep.Billing, address);
  };

  const getShippingMethodCode = () => {
    const additionalInfo = JSON.parse(user?.value?.additional_info || '{}');
    const customerHasAgent = !!additionalInfo?.codiceAgente && additionalInfo?.codiceAgente !== 'A    50';
    if (isPickup.value) {
      return 'pickup';
    }
    if (customerIsSubscribed.value) {
      return 'cliente_con_agente_abbonato';
    }
    if (customerHasAgent) {
      return 'cliente_con_agente';
    }
    return 'cliente_senza_agente';
  };

  const setShippingMethod = async () => {
    try {
      const visibleShippingMethods = ref();
      const selectedShippingMethod = ref();

      visibleShippingMethods.value = checkoutAvailableShippingMethods.value.filter((m) => {
        return m.method_code === getShippingMethodCode();
      });
      // eslint-disable-next-line prefer-destructuring
      selectedShippingMethod.value = visibleShippingMethods.value[0];
      await setShippingMethodOnCart(selectedShippingMethod.value);
    } catch (error) {
      console.error(error);
    }
  };

  /**
   * Sets the shipping address on the cart.
   * @param {boolean} [isNewAddress=false] - Indicates whether the shipping address is a new address or an existing one.
   * @param {boolean} [saveAddress=false] - Indicates whether the shipping address should be saved in the address book.
   * @throws Will throw an error if the request fails.
   * @returns {Promise<void>} A Promise that resolves when the shipping address has been successfully set on the cart.
   */
  const setShippingAddressOnCart = async (isNewAddress = false, saveAddress = false) => {
    stepLoading.value = true;
    try {
      const customerAddressId = checkoutShipping?.value?.id;
      await saveShippingAddress({
        params: {},
        shippingDetails: {
          ...(isNewAddress ? addressFormToApi(checkoutShipping.value) : addressFromApiToForm(checkoutShipping.value)),
          customerAddressId,
        },
      });
      const setError = findErrorInResponse(shippingErrors.value);
      if (setError) {
        notifyCheckoutError(CheckoutStep.Shipping, setError);
        stepLoading.value = false;
        return;
      }
      await setShippingMethod();
    } catch (error) {
      console.error(error);
    }
    stepLoading.value = false;
  };

  /**
   * Sets the billing address on the cart.
   * @param isNewAddress - Whether the billing address is a new address or an existing one.
   * @param useShippingAddress - Whether to use the shipping address as the billing address.
   */
  const setBillingAddressOnCart = async (isNewAddress = false, useShippingAddress = false) => {
    stepLoading.value = true;
    try {
      const customerAddressId = checkoutBilling?.value?.id;
      // eslint-disable-next-line no-underscore-dangle
      const addressToConsider = addressFromApiToForm(checkoutBilling.value);
      await setBillingAddressOnCartComposable({
        params: {},
        billingDetails: {
          ...(isNewAddress ? addressFormToApi(checkoutBilling.value) : addressToConsider),
          customerAddressId,
          sameAsShipping: useShippingAddress,
        },
        customQuery: {
          setBillingAddressOnCart: 'setBillingAddressOnCartCustom',
        },
      });
      const setError = findErrorInResponse(billingErrors.value);
      if (setError) {
        notifyCheckoutError(CheckoutStep.Billing, setError);
        stepLoading.value = false;
        return;
      }
      stepLoading.value = false;
    } catch (error) {
      console.error(error);
    }
    stepLoading.value = false;
  };

  /**
   * Sets the given address as the checkout shipping or billing address, depending on the given step.
   * @param {Object} address - The address to set as the checkout shipping or billing address.
   * @param {string} step - The step where the address should be set.
   * @param {boolean} [saveAddress=false] - Whether to save the address to the customer's address book.
   * @param {boolean} [setAsDefault=false] - Whether to set the address as the default shipping or billing address.
   * @throws Will throw an error if the request fails.
   * @returns {Promise<void>} A Promise that resolves when the address has been successfully set.
   */
  const setNewAddress = async (address: any, step: string, saveAddress = false, setAsDefault = false) => {
    if (saveAddress || setAsDefault) {
      await createCustomerAddress({
        address: {
          ...address,
          ...(setAsDefault ? { [`default_${step}`]: true } : {}),
        },
      });
      await loadUser();
    }
    if (step === CheckoutStep.Shipping) {
      await setCheckoutShipping(address);
      await setShippingAddressOnCart(true, saveAddress);
      return;
    }
    await setCheckoutBilling(address);
    await setBillingAddressOnCart(true);
  };

  /**
   * Handles the click event on a checkout step.
   * @param {number} step - The index of the step that was clicked.
   * @throws Will throw an error if the navigation fails.
   * @returns {Promise<void>} A Promise that resolves when the navigation to the clicked step has been successfully completed.
   */
  const handleStepClick = async (step: number) => {
    if (step < currentStepIndex.value) {
      const stepString = Object.keys(STEPS)[step];
      await router.push(`/${locale}/checkout/${stepString}`);
    }
  };

  const placeOrder = async () => {
    checkoutPlacingOrder.value = true;
    preservedCartNotes.value = cart.value?.notes;
    await placeOrderComposable({});
    checkoutPlacingOrder.value = false;
    const orderError = findErrorInResponse(orderErrors.value);
    if (orderError) {
      // Get response error code and message. If no code: return a string and print standard message
      const { code: errorCode, message: errorMessage } = getErrorDataFromPlaceResponse(orderErrors.value, orderError);
      if (!errorCode) {
        notifyCheckoutError(CheckoutStep.Payment, getErrorDataForNotification(orderError).key);
        return;
      }
      // Parse codes and messages to compose the notification content. Only 100 < code < 200 use original response message
      const { key, message } = getErrorDataForNotification(orderError, errorCode, errorMessage);
      // Pass a message string to override the content of the map. Used to print the original response message
      notifyCheckoutError(CheckoutStep.Payment, key, message);
      return;
    }
    await removeCart();
  };

  const handleNotLoggedIn = () => {
    redirect(401, '/it');
    sendNotification({
      id: Symbol('user_error'),
      message: `Effettua il login per accedere alla risorsa richiesta`,
      type: 'danger',
      icon: 'check',
      persist: false,
      title: `Non autorizzato`,
    });
  };

  onMounted(async () => {
    if (!user.value) {
      await loadUser();
    }
    if (!user.value && route?.value?.path?.includes('checkout')) {
      handleNotLoggedIn();
    }
    if (!cart.value && hasToMount && route?.value?.path?.includes('checkout')) {
      await loadCart();
    }
    if (cart?.value?.notes) preservedCartNotes.value = cart.value.notes;
    if (user.value) {
      await setCheckoutUser(user.value);
      if (!checkoutShipping.value) {
        await setCheckoutShipping(user.value.addresses, true);
      }
      if (!checkoutBilling.value) {
        await setCheckoutBilling(user.value.addresses, true);
      }
    }
    await checkBulkyProducts();
    checkoutLoading.value = false;
    // on one-page checkout causes race conditions
    // doesn't matter as sharedRef is handled by the various api calls
    // stepLoading.value = false;
  });

  const setCheckoutThankYouAdditionalData = (data: any) => {
    checkoutThankYouAdditionalData.value = data;
  };

  const setCheckoutOrderNotes = (notes: any) => {
    // order notes should always be a stringified JSON
    // but better be safe than sorry
    if (typeof notes === 'string') {
      try {
        checkoutOrderNotes.value = JSON.parse(notes);
        return;
      } catch (error) {
        console.error('useCheckout ~ setCheckoutOrderNotes ~ error:', error);
      }
    }
    checkoutOrderNotes.value = notes;
  };

  const setCheckoutOrderTotals = (totals: any) => {
    try {
      const totalItems = formatMoney(totals.subtotals.items.value);
      const appliedTaxes = formatMoney(totals.subtotals.tax.value);
      const total = formatMoney(totals.totals.ordered.value);
      const taxable = (Number.parseFloat(total) - Number.parseFloat(appliedTaxes)).toFixed(2);
      checkoutOrderTotals.value = {
        totalItems,
        appliedTaxes,
        total,
        taxable,
      };
    } catch (error) {
      console.error('setCheckoutOrderTotals ~ error:', error);
    }
  };

  const setCheckoutOrderShippingAddress = (address: any) => {
    checkoutOrderShippingAddress.value = address;
  };

  const resetCheckoutData = () => {
    setCheckoutOrderTotals(null);
    setCheckoutOrderNotes(null);
  };

  return {
    cart,
    checkoutLoading,
    currentStep,
    getCurrentStepIndex,
    setCheckoutUser,
    checkoutUser,
    checkoutShipping,
    checkoutBilling,
    checkoutPayment,
    STEPS,
    notifyCheckoutError,
    setCheckoutShipping,
    setShippingAddressOnCart,
    setNewAddress,
    checkoutAvailableShippingMethods,
    setShippingMethodOnCart,
    setCheckoutBilling,
    setBillingAddressOnCart,
    currentStepIndex,
    handleStepClick,
    loadPaymentMethods,
    placeOrder,
    order,
    cartCanBeCheckedOut,
    checkoutPlacingOrder,
    orderErrors,
    stepLoading,
    checkoutShippingAddressId,
    checkoutBillingAddressId,
    generateOrderSummaryPdf,
    checkoutThankYouAdditionalData,
    setCheckoutThankYouAdditionalData,
    preservedCartNotes,
    checkoutShippingIsBeingSet,
    isNormal,
    isPickup,
    setCheckoutOrderNotes,
    checkoutOrderNotes,
    setCheckoutOrderTotals,
    checkoutOrderTotals,
    resetCheckoutData,
    setCheckoutOrderShippingAddress,
    checkoutOrderShippingAddress,
  };
};

export default useCheckout;
