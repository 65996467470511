export enum InternalEndpoints {
  GetAdditionalOrderData = 'gemini/getAdditionalOrderData',
  GetCustomerPermissions = 'gemini/getCustomerPermissions',
  AddCustomerGroupToCustomer = 'gemini/addCustomerGroupToCustomer',
  SendCustomerCreationEvent = 'gemini/sendCustomerCreationEvent',
  GetStockData = 'gemini/getStockData',
  GetPublicSettings = 'gemini/getPublicSettings',
  GetShippingSettings = 'gemini/getShippingSettings',
  GetContestToken = 'gemini/yellow',
  GetOrderList = '/api/gemini/getOrderList',
  GetNotShippedOrders = '/api/gemini/getNotShippedOrders',
  GetOrder = '/api/gemini/getOrder',
  GetPayloadData = 'gemini/getPayloadData',
  GetBemilsUrl = 'gemini/bemils',
  ShareWishlist = 'gemini/shareWishlist',
  DeleteWishlist = 'gemini/deleteWishlist',
  SendMailToAgent = 'gemini/sendMailToAgent',
  GetAgentByCode = 'gemini/getAgentByCode',
  GetNppBySkus = 'gemini/getNppBySkus',
  GetProductPriceHistory = 'gemini/getProductPriceHistory',
  GetOrderBudget = 'gemini/getOrderBudget',
  ValidateRecaptcha = 'gemini/validateRecaptcha',
  GetExtranetGuid = 'gemini/getExtranetGuid',
  GetOrderDocuments = '/api/gemini/getOrderDocuments',
  IsCustomerInRole = 'gemini/isCustomerInRole',
  ListAttributeOptions = 'gemini/listAttributeOptions',
  EnrichCustomer = 'gemini/enrichCustomer',
  RegisterCustomerInEmbyon = 'gemini/registerCustomer',
  GetOrderInfoAfterPlacement = 'gemini/getOrderInfoAfterPlacement',
  OrderImport = '/api/gemini/orderImport',
  GetCustomerDestinazioniDiverse = 'gemini/getCustomerDestinazioniDiverse',
  LoadDiscounts = 'gemini/loadDiscounts',
}
