/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Order, OrderAddress } from '@gemini-vsf/api-client';
import { jsPDF as JSPDF } from 'jspdf';
import { applyPlugin } from 'jspdf-autotable';
import { shippingAddressIsPickup } from './shippingAddressIsPickup';
import { FOOTER_IMAGE_BASE64 } from './footerImageBase64';
import { MONTSERRAT_FONT_BASE64 } from './montserratFontBase64';

const { REGULAR, BOLD } = MONTSERRAT_FONT_BASE64;

const FOOTER_TEXT_FALLBACK = `Il "riepilogo d'ordine" non costituisce un documento ufficiale, ma solo un resoconto informativo. La conferma d'ordine, inviata dall'ufficio commerciale, è l'unico documento valido in cui prezzi, disponibilità e tempi di consegna sono stati verificati e confermati.`;

applyPlugin(JSPDF);

export interface IGenerateOrderSummaryPdfArgs {
  order: Order;
  checkoutUser?: any;
  shippingAddress?: OrderAddress;
  billingAddress?: OrderAddress;
  additionalOrderItemsData?: any;
  notifyCheckoutError: (step: string, key: string, errorMessage?: string) => void;
  $dateFns: any;
  footerText?: string;
  orderNotes?: Record<string, string>;
  orderShippingAddress?: any;
  totalNoTax?: number;
}

export type IGenerateOrderSummary = (arg0: IGenerateOrderSummaryPdfArgs) => boolean;

interface ExtendedJSPDF extends JSPDF {
  autoTable: any;
  lastAutoTable: any;
}

export const generateOrderSummaryPdf: IGenerateOrderSummary = ({
  order,
  checkoutUser,
  shippingAddress,
  additionalOrderItemsData,
  notifyCheckoutError,
  $dateFns,
  footerText,
  orderNotes,
  orderShippingAddress,
  totalNoTax,
}) => {
  try {
    const orderCurrency = order.items[0]?.product?.price_range?.minimum_price?.final_price?.currency || 'EUR';
    const doc = new JSPDF() as ExtendedJSPDF;

    doc.addFileToVFS('MontserratRegular.ttf', REGULAR);
    doc.addFileToVFS('MontserratBold.ttf', BOLD);
    doc.addFont('MontserratRegular.ttf', 'MontserratRegular', 'normal');
    doc.addFont('MontserratBold.ttf', 'MontserratBold', 'bold');

    const pageWidth = doc.internal.pageSize.width;
    const headerHeight = 605 * (pageWidth / 2480); // Maintain aspect ratio
    doc.addImage(FOOTER_IMAGE_BASE64, 'PNG', 0, 0, pageWidth, headerHeight);

    doc.setFontSize(15);

    // Title: Riepilogo Ordine
    doc.autoTable({
      startY: headerHeight,
      head: [['Riepilogo Ordine']],
      headStyles: {
        fillColor: '#FFFFFF',
        textColor: '#000000',
        font: 'MontserratBold',
        fontStyle: 'bold',
        halign: 'left',
      },
      theme: 'plain',
    });

    const formattedDate = $dateFns.format(new Date(order.created_at), "EEEE d/MM/yyyy 'ore' HH:mm", { locale: 'it' });
    const orderDetails = [['Data Ordine', formattedDate]];

    let orderReference: string | null = null;
    let deliveryPreferenceDate: string | null = null;
    try {
      orderReference = orderNotes?.orderReference;
      deliveryPreferenceDate = orderNotes?.deliveryPreference;
      if (deliveryPreferenceDate) {
        orderDetails.push(['Data consegna richiesta', deliveryPreferenceDate]);
      }
      if (orderReference) {
        orderDetails.unshift(['Riferimento Ordine', orderReference]);
      }
    } catch (error) {
      console.log('could not parse cart notes for order summary', error);
    }

    // Adjusted space between "Riepilogo Ordine" and its table
    doc.autoTable({
      startY: doc.lastAutoTable.finalY,
      head: [['Campo', 'Dettaglio']],
      body: orderDetails,
      headStyles: {
        fillColor: '#FDC300',
        textColor: '#000000',
        font: 'MontserratBold',
        fontStyle: 'bold',
      },
      styles: { font: 'MontserratRegular', textColor: '#000000' },
    });

    let finalY = doc.lastAutoTable.finalY + 3; // Adjusted space

    if (checkoutUser) {
      const name = `${checkoutUser.value?.firstname?.replace(/ /g, '') || ''} ${checkoutUser.value?.lastname?.replace(/ /g, '') || ''}`;

      // Title: Informazioni Utente
      doc.autoTable({
        startY: finalY,
        head: [['Informazioni Utente:']],
        headStyles: {
          fillColor: '#FFFFFF',
          textColor: '#000000',
          font: 'MontserratBold',
          fontStyle: 'bold',
          halign: 'left',
        },
        theme: 'plain',
      });

      doc.autoTable({
        startY: doc.lastAutoTable.finalY,
        head: [['Campo', 'Dettaglio']],
        headStyles: {
          fillColor: '#FDC300',
          textColor: '#000000',
          fontStyle: 'bold',
          font: 'MontserratBold',
        },
        styles: { font: 'MontserratRegular', textColor: '#000000' },
        body: [
          ['Nome', name || ''],
          ['Azienda', checkoutUser.value?.company_name || ''],
          ['Email', checkoutUser.value?.email || ''],
        ],
      });
      finalY = doc.lastAutoTable.finalY + 3; // Adjusted space
    }

    if (shippingAddress) {
      // Title: Indirizzo di Spedizione
      doc.autoTable({
        startY: finalY,
        head: [['Indirizzo di Spedizione:']],
        headStyles: {
          fillColor: '#FFFFFF',
          textColor: '#000000',
          font: 'MontserratBold',
          fontStyle: 'bold',
          halign: 'left',
        },
        theme: 'plain',
      });

      doc.autoTable(
        shippingAddressIsPickup(orderShippingAddress)
          ? {
            startY: doc.lastAutoTable.finalY,
            head: [['Nome', 'Indirizzo', 'Città', 'CAP', 'Paese']],
            headStyles: {
              fillColor: '#FDC300',
              textColor: '#000000',
              fontStyle: 'bold',
              font: 'MontserratBold',
            },
            styles: { font: 'MontserratRegular', textColor: '#000000' },
            body: [['COLFERT S.p.A.', 'Via Raffaello Sanzio 31', 'MOGLIANO VENETO', '31021', 'IT']],
          }
          : {
            startY: doc.lastAutoTable.finalY,
            head: [['Nome', 'Indirizzo', 'Città', 'CAP', 'Paese']],
            headStyles: {
              fillColor: '#FDC300',
              textColor: '#000000',
              fontStyle: 'bold',
              font: 'MontserratBold',
            },
            styles: { font: 'MontserratRegular', textColor: '#000000' },
            body: [
              [
                `${shippingAddress.firstname || ''} ${shippingAddress.lastname || ''}`,
                `${shippingAddress.street || ''}`,
                `${shippingAddress.city || ''}`,
                `${shippingAddress.postcode || ''}`,
                `${shippingAddress.country_code || ''}`,
              ],
            ],
          }
      );
      finalY = doc.lastAutoTable.finalY + 3; // Adjusted space
    }

    // Title: Articoli
    doc.autoTable({
      startY: finalY,
      head: [['Articoli:']],
      headStyles: {
        fillColor: '#FFFFFF',
        textColor: '#000000',
        font: 'MontserratBold',
        fontStyle: 'bold',
        halign: 'left',
      },
      theme: 'plain',
    });

    const hasMeasurementUnit = order.items.some((item) => additionalOrderItemsData?.[item.product.sku]?.unitadimisura_attribute?.label);
    const items = order.items.map((item) => {
      const variantName = additionalOrderItemsData?.[item.product.sku]?.variants?.find((itemVariant) => itemVariant.product.merchant_sku === item.product.sku)?.product?.name;
      return      [
        item.product.sku || '',
        variantName || '',
        hasMeasurementUnit ? additionalOrderItemsData?.[item.product.sku]?.unitadimisura_attribute?.label || '' : null,
        item.qtyOrdered || '',
        `${Number.parseFloat(item.unitListPrice).toFixed(2)} ${orderCurrency}` || '',
        `${Number.parseFloat(item.rowSalePrice).toFixed(2)} ${orderCurrency}` || '',
      ].filter((el: string | null) => el !== null) }
    );

    if (hasMeasurementUnit) {
      items.push(['', '', '', '', 'Totale imponibile (IVA escl.)', `${Number(totalNoTax).toFixed(2)} ${orderCurrency}`]);
    } else {
      items.push(['', '', '', 'Totale imponibile (IVA escl.)', `${Number(totalNoTax).toFixed(2)} ${orderCurrency}`]);
    }

    const head = hasMeasurementUnit
      ? [['Codice Articolo', 'Descrizione Articolo', 'Unità di Misura', 'Qta', 'Prezzo Unitario', 'Importo']]
      : [['Codice Articolo', 'Descrizione Articolo', 'Qta', 'Prezzo Unitario', 'Importo']];

    doc.autoTable({
      startY: doc.lastAutoTable.finalY,
      head,
      body: items,
      headStyles: {
        fillColor: '#FDC300',
        textColor: '#000000',
        fontStyle: 'bold',
        font: 'MontserratBold',
      },
      styles: { font: 'MontserratRegular', textColor: '#000000' },
    });

    const  orderNotesToPrint = orderNotes?.orderNotes || '';

    if (orderNotesToPrint !== '') {
      doc.autoTable({
        startY: doc.lastAutoTable.finalY,
        head: [['Note:']],
        body: [[orderNotesToPrint]],
        headStyles: {
          fillColor: '#FFFFFF',
          textColor: '#000000',
          font: 'MontserratBold',
          fontStyle: 'bold',
          halign: 'left',
        },
        theme: 'plain',
      });

    }

    doc.autoTable({
      startY: doc.lastAutoTable.finalY, // Adjusted space
      body: [[footerText || FOOTER_TEXT_FALLBACK]],
      theme: 'plain',
      styles: {
        fontSize: 10,
        font: 'MontserratRegular',
        textColor: '#000000',
        cellPadding: { top: 10, right: 0, bottom: 0, left: 0 },
      },
    });

    doc.save(`colfert-ordine-${orderReference || formattedDate}.pdf`);
    return true;
  } catch (error) {
    console.error('generateOrderSummaryPdf ~ error:', error);
    notifyCheckoutError('thankYou', 'generatePdf');
    return false;
  }
};
