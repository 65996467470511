/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useContext } from '@nuxtjs/composition-api';
import { GeminiEntityPrefixes } from '~/types/enums';
import { Product, useFacet, useCategorySearch } from '@gemini-vsf/composables';
import { Aggregation, CategoryTree } from '@gemini-vsf/api-client';
import { sharedRef, AgnosticFacetSearchParams } from '@vue-storefront/core';
import seoHelpers from '~/helpers/seo/seoHelpers';
import { useUiHelpers } from '~/composables';
import { filterLabelsMap } from '~/helpers/maps';
import { CategoryPageComposable, Breadcrumb } from '~/types/types';
import {
  filterFacets,
  generateBreadcrumbs,
  generateSeoData,
  addParameterToUrl,
  removeParameterFromUrl,
  generateSelectableAvailableFilters,
  updateSelectedFilters,
  replaceParameterInUrl,
  deleteParameterFromUrl,
  scrollToTop,
  removeAllParametersFromUrl,
} from './_helpers';

/**
 * Provides functionality for a category page.
 * @param {string} categoryId - The ID of the category.
 * @returns {CategoryPageComposable} The composable for the category page.
 */
const useCategoryPage = (categoryId: string): CategoryPageComposable => {
  const BASE_INPUT = {
    categoryId: `${GeminiEntityPrefixes.ProductList}${categoryId}`,
    customQuery: {
      products: 'productListCustom',
    },
  };
  const {
    $vsf: {
      $gemini: {
        config: {
          // @ts-ignore
          facets: { available: availableFacets },
        },
      },
    },
    app: { localePath },
    route: {
      value: { path, query },
    },
    $config: { storeUrl },
  } = useContext();
  const { search: loadFacetsResult, result: resultFromFacets } = useFacet();
  const { search: loadAdditionalCategoryData, result: resultFromCategorySearch } = useCategorySearch();
  const { getAlternates, getCanonical } = seoHelpers();
  const { getFacetsFromURL } = useUiHelpers();

  const products = sharedRef<Product[] | null>(null, `category-page-products-${categoryId}`);
  const productsCurrentlyInCategory = sharedRef<number>(0, `category-page-products-currently-in-category-${categoryId}`);
  const loadingProducts = sharedRef<boolean>(true, `category-page-loading-products-${categoryId}`);
  const categoryData = sharedRef<CategoryTree | null>(null, `category-page-category-data-${categoryId}`);
  const loadingCategoryData = sharedRef<boolean>(true, `category-page-loading-category-data-${categoryId}`);
  const breadcrumbs = sharedRef<Breadcrumb[] | null>(null, `category-page-breadcrumbs-${categoryId}`);
  const seoData = sharedRef<Record<string, unknown> | null>(null, `category-page-seo-data-${categoryId}`);
  const availableFilters = sharedRef<Aggregation[] | null>(null, `category-page-available-filters-${categoryId}`);
  const selectableAvailableFilters = sharedRef(null, `category-page-selectable-available-filters-${categoryId}`);
  const availableSortingOptions = sharedRef(null, `category-page-available-sorting-options-${categoryId}`);
  const currentFilters = sharedRef({}, `category-page-current-filters-${categoryId}`);
  const currentPage = sharedRef(Number.parseInt(query?.page as string, 10) || 1, `category-page-current-page-${categoryId}`);
  const currentSorting = sharedRef((query?.sort as string) || '', `category-page-current-sorting-${categoryId}`);
  const numberOfPages = sharedRef(1, `category-page-number-of-pages-${categoryId}`);

  /**
   * Loads products based on the current filters.
   * @returns {Promise<void>}
   */
  const loadProducts = async (): Promise<boolean> => {
    loadingProducts.value = true;
    try {
      currentFilters.value = {
        ...getFacetsFromURL(),
        ...currentFilters.value,
        page: currentPage.value as string,
        sort: currentSorting.value as string,
        ...BASE_INPUT,
      } as AgnosticFacetSearchParams;
      // handle discontinuo_1 filter, these products can only be visible in search results or in the /discontinui page
      const getQueryFilters = () => {
        const currentFiltersValue = currentFilters.value?.filters || {};
        return path.includes('discontinui')
          ? ({ ...currentFiltersValue } as Record<string, string[]>)
          : ({
              ...currentFiltersValue,
              discontinuo_1: ['false'],
            } as Record<string, string[]>);
      };
      currentFilters.value.filters = getQueryFilters();
      await loadFacetsResult(currentFilters.value as AgnosticFacetSearchParams);
      products.value = resultFromFacets?.value?.data?.items as Product[];
      availableSortingOptions.value = resultFromFacets?.value?.data?.availableSortingOptions as string[];
      productsCurrentlyInCategory.value = (resultFromFacets?.value?.data?.total as number) || 0;
      numberOfPages.value = Math.ceil(productsCurrentlyInCategory.value / 16);
      availableFilters.value = filterFacets(resultFromFacets?.value?.data?.availableFilters as Aggregation[], availableFacets as string[]);
      selectableAvailableFilters.value = generateSelectableAvailableFilters(
        availableFilters.value as Aggregation[],
        currentFilters?.value?.filters as Record<string, string[]>
      );
      loadingProducts.value = false;
      return true;
    } catch (error) {
      console.error(error);
      loadingProducts.value = false;
      return false;
    }
  };

  /**
   * Loads additional data for the category.
   * Such as the category data, breadcrumbs and SEO data.
   * @returns {Promise<void>}
   */
  const loadAdditionalData = async (): Promise<boolean> => {
    try {
      await loadAdditionalCategoryData({
        filters: {
          category_uid: {
            eq: categoryId,
          },
        },
        customQuery: {
          categorySearch: 'categoryListCustom',
        },
      });
      categoryData.value = resultFromCategorySearch.value?.[0] as CategoryTree;
      breadcrumbs.value = generateBreadcrumbs(categoryData.value as CategoryTree, localePath, path);
      const alternates = getAlternates();
      seoData.value = generateSeoData(
        categoryData.value as CategoryTree,
        alternates,
        breadcrumbs.value as Breadcrumb[],
        localePath,
        storeUrl as string,
        getCanonical()
      );
      loadingCategoryData.value = false;
      return true;
    } catch (error) {
      console.error(error);
      loadingCategoryData.value = false;
      return false;
    }
  };

  /**
   * Adds or removes a filter.
   * @param {string} filter - The filter to add or remove.
   * @param {string} value - The value of the filter.
   */
  const addOrRemoveFilter = (filter: string, value: string) => {
    const {
      value: { filters },
    } = currentFilters;
    const filterExists = filters[filter]?.includes(value);

    filters[filter] = filterExists ? filters[filter].filter((v) => v !== value) : [...(filters[filter] || []), value];

    if (filterExists) removeParameterFromUrl(filter, value, path);
    else addParameterToUrl(filter, value, path);

    selectableAvailableFilters.value = updateSelectedFilters(
      selectableAvailableFilters.value as Aggregation[],
      filter,
      value,
      filterExists as boolean
    );

    currentFilters.value = { ...currentFilters.value, filters };
  };

  /**
   * Resets all filters.
   * @returns {Promise<void>}
   */
  const resetFilters = async (): Promise<void> => {
    removeAllParametersFromUrl();
    currentFilters.value = {};
    currentPage.value = 1;
    await loadProducts();
    scrollToTop();
  };

  /**
   * Changes the current page number.
   * @param {number} page - The new page number.
   * @returns {Promise<void>}
   */
  const changePageNumber = async (page: number): Promise<void> => {
    if (page === currentPage.value || page < 1 || page > numberOfPages.value) return;
    currentPage.value = page;
    replaceParameterInUrl('page', page.toString(), path);
    scrollToTop();
    await loadProducts();
  };

  /**
   * Changes the current sorting option.
   * @param {string} sorting - The new sorting option.
   * @returns {Promise<void>}
   */
  const changeSorting = async (sorting: string): Promise<void> => {
    if (sorting === currentSorting.value) return;
    currentSorting.value = sorting;
    if (sorting === '') deleteParameterFromUrl('sort', path);
    else replaceParameterInUrl('sort', sorting, path);
    await loadProducts();
  };

  return {
    loadProducts,
    loadAdditionalData,
    addOrRemoveFilter,
    resetFilters,
    changePageNumber,
    changeSorting,
    products,
    availableFilters,
    categoryData,
    breadcrumbs,
    seoData,
    selectableAvailableFilters,
    currentFilters,
    filterLabelsMap,
    loadingProducts,
    loadingCategoryData,
    productsCurrentlyInCategory,
    currentPage,
    numberOfPages,
    availableSortingOptions,
    currentSorting,
  };
};

export default useCategoryPage;
