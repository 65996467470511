<template>
  <div>
    <IconSprite />
    <WishlistSidebar v-if="isWishlistSidebarOpen" />
    <FiltersSidebar v-if="isFilterSidebarOpen" />
    <CartSidebar v-if="isCartSidebarOpen" />
    <LoginAndRegisterModal v-if="loginAndRegisterModalOpen" />
    <QuoteModal v-if="isQuoteModalOpen" />
    <WishlistModal v-if="isWishlistModalOpen" />
    <CreateWishlistModal v-if="isCreateWishlistModalOpen" />
    <LazyHydrate when-visible>
      <Notification />
    </LazyHydrate>
    <AppHeader />
    <div id="layout">
      <nuxt :key="route.fullPath" />
    </div>
    <LazyHydrate when-visible>
      <AppFooter />
    </LazyHydrate>
  </div>
</template>

<script>
import LazyHydrate from 'vue-lazy-hydration';
import {
  useRoute,
  defineComponent,
  ref,
  useFetch,
  onMounted,
  useMeta,
  useContext,
} from '@nuxtjs/composition-api';
import AppHeader from '~/components/AppHeader.vue';
import CartSidebar from '~/components/CartSidebar.vue';
import LoginAndRegisterModal from '~/components/LoginAndRegisterModal.vue';
import { useGeminiConfiguration } from '~/composables/useGeminiConfiguration';
import {
  useTranslation,
  useUser,
  useUiState,
  useCart,
  useUserWishlists,
  useDiscounts,
} from '~/composables/';
import QuoteModal from '~/components/General/Modals/QuoteModal.vue';
import Notification from '~/components/Notification.vue';
import { WishlistSidebar, FiltersSidebar } from '~/components/Sidebars';
import { WishlistModal, CreateWishlistModal } from '~/components/Modals';

export default defineComponent({
  name: 'DefaultLayout',

  components: {
    LazyHydrate,
    AppHeader,
    WishlistSidebar,
    FiltersSidebar,
    AppFooter: () =>
      import(/* webpackPrefetch: true */ '~/components/AppFooter.vue'),
    CartSidebar,
    LoginAndRegisterModal,
    Notification,
    IconSprite: () =>
      import(/* webpackPrefetch: true */ '~/components/General/IconSprite.vue'),
    QuoteModal,
    WishlistModal,
    CreateWishlistModal,
  },
  head: {},
  setup() {
    const route = useRoute();
    const {
      isAuthenticated,
      user,
      load: loadUser,
      loading: loadingUser,
      setAuthenticatedState,
    } = useUser();
    const {
      app: { $cookies },
    } = useContext();
    const { cart, load: loadCart, checkBulkyProducts } = useCart();
    const flag = ref(false);
    const { discounts, loadDiscounts } = useDiscounts();
    const { loadTranslations } = useTranslation('translations');
    const cookiebot = process && process.browser && window && window.Cookiebot;
    const hasAlreadyDeclared = !!$cookies.get('CookieConsent');

    const {
      isCartSidebarOpen,
      isWishlistSidebarOpen,
      loginAndRegisterModalOpen,
      isQuoteModalOpen,
      isWishlistModalOpen,
      isCreateWishlistModalOpen,
      isFilterSidebarOpen,
    } = useUiState();
    const { loadUserWishlists, loadNumberOfItemsInWishlist } =
      useUserWishlists();

    const { loadConfiguration } = useGeminiConfiguration();

    onMounted(async () => {
      if (cookiebot && !hasAlreadyDeclared) {
        window.addEventListener(
          'CookiebotOnDecline',
          () => {
            if (window.CookiebotDialog) window.location.reload();
          },
          false
        );
      }
      if (!user.value && !loadingUser.value) {
        await loadUser();
      }
      if (!cart.value) {
        await loadCart();
      }
      if (discounts.value.length === 0) {
        await loadDiscounts();
      }
      await loadUserWishlists();
      await loadNumberOfItemsInWishlist();
      await checkBulkyProducts();
    });

    useFetch(async () => {
      setAuthenticatedState();
      await loadTranslations();
      loadConfiguration();
    });

    useMeta({
      htmlAttrs: {
        lang: route?.value?.path?.split('/')?.[1] === 'it' ? 'it' : 'en',
      },
    });

    return {
      ...useUserWishlists(),
      ...useTranslation('translations'),
      route,
      isAuthenticated,
      flag,
      user,
      isCartSidebarOpen,
      isWishlistSidebarOpen,
      loginAndRegisterModalOpen,
      isQuoteModalOpen,
      isWishlistModalOpen,
      isCreateWishlistModalOpen,
      isFilterSidebarOpen,
      cart,
    };
  },
});
</script>

<style lang="scss">
@import '~@storefront-ui/vue/styles';
@import '@storefront-ui/shared/styles/helpers/_colors.scss';
@import '../assets/style/variables.scss';
@import '../assets/style/default.scss';
</style>
