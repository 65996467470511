import { useDiscounts } from '~/composables';

type Discount = {
  from: number;
  to?: number;
  discount: number;
};

const defaultBundleDiscount = 0.06;

export const getDiscountedPrice = (basePrice: number, finalPrice: number, qty = 1): number => {
  const { discounts } = useDiscounts();
  const rowPrice = basePrice * qty;

  const foundDiscount = (discounts.value as Discount[]).find(
    (discountRange: Discount) => rowPrice >= discountRange.from && (rowPrice <= discountRange.to || !discountRange.to)
  );

  return foundDiscount?.discount ? basePrice * (1 - foundDiscount.discount) : finalPrice;
};

export const getBundleDiscountedPrice = (price: number, bundleQty = 1, qty = 1, discount = defaultBundleDiscount) => {
  if (bundleQty === 1 || qty < bundleQty) {
    return price;
  }

  const invertedDiscount = 1 - discount;

  const remainder = qty % bundleQty;
  if (remainder === 0) {
    return price * invertedDiscount;
  }

  const realDiscount = 1 - (remainder + invertedDiscount * (bundleQty - remainder)) / bundleQty;

  return price * (1 - realDiscount);
};

export const getSplittedBundledPrice = (rowTotalPrice: number, bundleQty: number, qty: number, discount = defaultBundleDiscount) => {
  const remainder = qty % bundleQty;
  const unitPrice = rowTotalPrice / (qty - discount * (qty - remainder));
  const remainderPrice = unitPrice * remainder;

  return {
    bundledQty: qty - remainder,
    bundledPrice: rowTotalPrice - remainderPrice,
    packageQty: Math.floor(qty / bundleQty),
    remainingQty: remainder,
    remainingPrice: remainderPrice,
  };
};
