<template>
  <div
    v-if="optionsArray"
    class="custom-select__container"
    :style="specificWidth ? { width: specificWidth } : {}"
  >
    <ValidationProvider
      :rules="isRequired ? 'required' : ''"
      v-slot="{ errors }"
      class="custom-select__validator"
      :class="isDisabled && 'custom-select__container__disabled'"
    >
      <span class="custom-select__title"> {{ $gt(selectTitle) }}</span>
      <t-rich-select
        class="custom-select"
        :required="isRequired"
        :valid="isValid"
        :disabled="isDisabled"
        :placeholder="$gt(placeholder)"
        @change="handleChange"
        :value="selectValue || (defaultValue && defaultValue.value)"
        :class="blackOutline && 'custom-select__black-outline'"
        :style="specificWidth ? { width: specificWidth } : {}"
        :options="optionsArray"
        :key="`custom-select-${componentKey}`"
      >
        <!-- eslint-disable -->
        <template
          slot="option"
          slot-scope="{ index, isHighlighted, isSelected, className, option, query }"
        >
          <div
            :key="`custom-select__option-${option.value + index}`"
            class="custom-select__option"
            :class="[
              className,
              isHighlighted && 'custom-select__option--highlighted',
              isSelected && 'custom-select__option--selected',
            ]"
          >
            <span class="custom-select__option__label">{{ option.text }}</span>
            <a
              href="javascript:;"
              class="custom-select__option__link"
              @click="(e) => handleDeleteWishlist(e, option.value)"
            >
            </a>
          </div>
        </template>
      </t-rich-select>
      <span v-if="errors[0]" class="custom-select__error">
        {{ $t(errors[0]) }}
      </span>
    </ValidationProvider>
  </div>
</template>

<script>
import { defineComponent, ref, computed, watch } from '@nuxtjs/composition-api';
import { ArrowDown } from '~/components/General/Icons';
import { ValidationProvider, extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import { useI18n } from '~/helpers/hooks/usei18n';
import { useGeminiApi, useTranslation } from '~/composables';

export default defineComponent({
  name: 'CustomRichSelect',
  components: {
    ValidationProvider,
    // eslint-disable-next-line vue/no-unused-components
    ArrowDown,
  },
  model: {
    prop: 'selectedValue',
  },
  props: {
    selectName: {
      type: String,
      required: true,
    },
    selectOptions: {
      type: Array,
      required: true,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    selectIcon: {
      type: [Boolean, String],
      default: 'ArrowDown',
    },
    selectLabel: {
      type: String,
      default: 'Seleziona una opzione',
    },
    selectedValue: {
      type: [String, null],
      default: null,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    specificWidth: {
      type: [String, undefined],
      default: undefined,
    },
    isValid: {
      type: Boolean,
      default: true,
    },
    selectTitle: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: 'Select option',
    },
    blackOutline: {
      type: Boolean,
      default: false,
    },
    reloadOptions: {
      type: Function,
      default: () => {},
    },
  },
  setup(props, { emit, root }) {
    const options = ref([]);
    const componentKey = ref(0);
    const forceRerender = () => {
      componentKey.value += 1;
    };

    const selectValue = ref(props.selectedValue);
    const defaultValue = ref();
    const trans = useI18n();
    extend('required', {
      ...required,
      message: trans.t('Required field'),
    });
    const optionsArray = computed(() =>
      props.selectOptions && props.selectOptions.length > 0
        ? props.selectOptions
            .map((e) => {
              e.text = e.label;
              if (!Object.hasOwn(e, 'label')) {
                e.label = e.value;
                e.text = e.value;
              }
              if (Object.hasOwn(e, 'default')) {
                defaultValue.value = e;
                return null;
              }
              return e;
            })
            .filter(Boolean)
        : []
    );
    const { $gt } = useTranslation('translations');

    const handleChange = (value) => {
      selectValue.value = value;
      emit('selectChange', value);
      // emit('reloadOptions');
      emit('input', value);
    };

    watch(optionsArray, (optionsArrayReference) => {
      if (optionsArrayReference.length === 1) {
        selectValue.value = optionsArrayReference[0].value.toString();
        handleChange(selectValue.value);
      }
    });

    watch(
      () => props.selectedValue,
      () => {
        selectValue.value = props.selectedValue;
        emit('input', props.selectedValue);
      }
    );

    const { deleteWishlist } = useGeminiApi();

    const handleDeleteWishlist = (e, optionValue) => {
      e.preventDefault();
      e.stopPropagation();
      if (props.isDisabled) return false;
      if (selectValue.value && optionValue) {
        root.$dialog
          .confirm({
            title: 'Sei sicuro di voler eliminare la cartella dei preferiti?',
            okButtonText: 'Si',
            cancelButtonText: 'No',
          })
          .then((dE) => {
            if (!dE.isOk) return false;
            // eslint-disable-next-line promise/no-nesting
            deleteWishlist(optionValue)
              .then((result) => {
                // wait 2 seconds
                // setTimeout(() => {
                props.reloadOptions();
                // }, 2000);
                return true;
              })
              .catch((error) => {
                console.log(error);
                return false;
              });
            return true;
          })
          .catch((error) => {
            console.log('error', error);
          });
        return false;
      }
      return false;
    };

    return {
      componentKey,
      handleDeleteWishlist,
      optionsArray,
      options,
      selectValue,
      handleChange,
      defaultValue,
      $gt,
    };
  },
});
</script>

<style lang="scss">
.custom-select {
  padding: 0;

  &::after {
    display: none !important;
  }

  &__option {
    @include paragraph-regular;
  }

  &__title {
    @include cta-text;
    text-transform: uppercase;
  }

  &__container {
    .custom-select {
      &__validator {
        line-height: 14px;

        &__title {
          display: block;
          margin-bottom: 3px;
        }
      }

      &__option {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &__label {
          display: flex;
        }

        &__link {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 16px;
          height: 16px;
          font-size: 24px;
          color: var(--c-black);
          cursor: pointer;
          &:after {
            font-size: 24px;
            display: flex;
            content: '\00d7';
          }
        }
      }
    }
  }

  &__container,
  .sf-select__dropdown {
    @include form-element-default-mobile-size;
  }

  .sf-select {
    @include default-outline;

    &__dropdown {
      margin: 0;
      appearance: none;
      background-image: url('/icons/ArrowDown.svg');
      background-repeat: no-repeat;
      background-position-x: 97%;
      background-position-y: 0.8rem;
      width: 100%;
      padding: 0 3rem 0 1rem;
    }

    &__label {
      display: none;
    }
  }

  &__container {
    padding-bottom: 4rem;
  }

  &__black-outline {
    .sf-select__dropdown {
      outline: 1px solid var(--c-black) !important;
    }
  }
}

@include from-desktop-min {
  .custom-select {
    &__container,
    .sf-select__dropdown {
      width: 100%;
      height: var(--form-element-desktop-height);
    }
  }
}
</style>
